import React, { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

interface PropTypes extends ComponentProps<"select"> {
  label?: string;
  options: { label: string; value: string }[];
  error?: string | any;
  isInvalid?: boolean;
  containerClassName?: string;
  renderOption?: (option: any) => React.ReactNode;
}

const Select = ({
  label,
  isInvalid = false,
  placeholder,
  options,
  error,
  containerClassName,
  className,
  renderOption,
  ...rest
}: PropTypes) => {
  return (
    <div className={twMerge("flex flex-col", containerClassName)}>
      {label && <label className="label">{label}</label>}
      <select
        {...rest}
        defaultValue=""
        className={twMerge(
          "select select-bordered select-primary focus:border-none text-black",
          !rest.value ? "text-gray-400" : "text-black",
          "[&_option:not(:first-child)]:text-black",
          className
        )}
      >
        <option disabled hidden value="" className="text-gray-400">
          {placeholder}
        </option>
        {options.map((option, index) =>
          renderOption ? (
            renderOption(option)
          ) : (
            <option key={option.value + index} value={option.value}>
              {option.label}
            </option>
          )
        )}
      </select>
      {isInvalid && (
        <div className="label">
          <span className="label-text-alt text-red-600">{error}</span>
        </div>
      )}
    </div>
  );
};

export default Select;
