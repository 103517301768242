import React, { useState } from "react";
import Button from "./Button";
import ApiManager from "../utils/ApiManager";
import { toast } from "react-toastify";

interface VerifyDriverBackgroundBtnProps {
  id: string;
}

const VerifyDriverBackgroundBtn = ({ id }: VerifyDriverBackgroundBtnProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleBackgroundCheck = async () => {
    setIsLoading(true);
    const res = await new ApiManager().verifyDriverBackground(id);
    if (res?.success) {
      toast(
        res?.data?.message || "Background check is requested successfully",
        { type: "success" }
      );
    } else {
      toast(
        res?.error ||
          "Something went wrong while requesting background check. Kindly try again later.",
        { type: "error" }
      );
    }
    setIsLoading(false);
  };

  return (
    <Button isLoading={isLoading} onClick={handleBackgroundCheck}>
      Verify Driver Background
    </Button>
  );
};

export default VerifyDriverBackgroundBtn;
