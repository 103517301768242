import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AddOfferModal from "../components/AddOfferModal";
import { serviceTypes } from "../components/AddPromoCodeModal";
import Button from "../components/Button";
import ConfirmationDialog from "../components/ConfirmationDialog";
import DataListView from "../components/DataListView";
import DatePicker from "../components/DatePicker";
import EditOfferModal from "../components/EditOfferModal";
import FiltersContainer from "../components/FiltersContainer";
import Select from "../components/Select";
import Toggle from "../components/Toggle";
import ApiManager from "../utils/ApiManager";
import { capitalize, formatDate } from "../utils/helpers";
import { formatServiceTypes } from "./PromoCodes";

export type Offer = {
  _id: string;
  discountValue: number;
  discountType: string;
  validFrom: string;
  validTo?: string;
  serviceType: [
    "package_pickup_meta" | "curbside_pickup_meta" | "instore_pickup_meta"
  ];
  pointsRequired: number;
  isOfferActive: boolean;
};

const Offers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAddOfferModalVisible, setIsAddOfferModalVisible] = useState(false);
  const [offers, setOffers] = useState<Offer[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<Offer | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeletingOffer, setIsDeletingOffer] = useState(false);
  const [offerStatusChangeId, setOfferStatusChangeId] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [validTo, setValidTo] = useState("");
  const [isActive, setIsActive] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [filters, setFilters] = useState({
    validFrom: "",
    validTo: "",
    isActive: "",
    serviceType: "",
  });

  const handleGetAllOffers = async () => {
    setIsLoading(true);
    const { serviceType, ...rest } = filters;
    const res = await new ApiManager().getAllOffers({
      page: currentPage,
      serviceType: serviceType,
      ...rest,
    });
    if (res.success) {
      setOffers(res.data?.docs);
      setTotalPages(res.data?.totalPages);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetAllOffers();
  }, [
    currentPage,
    filters.isActive,
    filters.validFrom,
    filters.validTo,
    filters.serviceType,
  ]);

  const handleDeleteOffer = async () => {
    setIsDeletingOffer(true);
    const res: any = await new ApiManager().deleteOffer(
      selectedOffer?._id || ""
    );
    if (res.success) {
      toast("Offer Deleted Successfully", { type: "success" });
      const updatedOffers = offers.filter(
        (offer) => offer._id !== selectedOffer?._id
      );
      if (updatedOffers.length === 0 && currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1);
      } else {
        handleGetAllOffers();
      }
      setIsDeleteModalOpen(false);
      setSelectedOffer(null);
    } else {
      toast(res.error?.response?.data?.error, { type: "error" });
    }
    setIsDeletingOffer(false);
  };

  const handleOfferStatusChange = async (
    id: string,
    isOfferActive: boolean
  ) => {
    setOfferStatusChangeId(id);
    const res = await new ApiManager().toggleOfferStatus({
      id,
      isOfferActive: !isOfferActive,
    });
    if (res.success) {
      const arr = [...offers];
      const index = arr.findIndex((item) => item._id === id);
      arr[index].isOfferActive = !isOfferActive;
      setOffers(arr);
    }
    setOfferStatusChangeId("");
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setFilters({
      validFrom,
      validTo,
      serviceType,
      isActive,
    });
  };

  const clearFilters = () => {
    setCurrentPage(1);
    setFilters({
      validFrom: "",
      validTo: "",
      serviceType: "",
      isActive: "",
    });
    setValidFrom("");
    setValidTo("");
    setServiceType("");
    setIsActive("");
  };

  const isAnyFilterApplied = () => {
    return (
      serviceType !== "" ||
      isActive !== "" ||
      validFrom !== "" ||
      validTo !== ""
    );
  };

  const isApplyButtonDisabled = () => {
    return !isAnyFilterApplied();
  };

  const isClearButtonDisabled = () => {
    return (
      validFrom === "" &&
      validTo === "" &&
      serviceType === "" &&
      isActive === ""
    );
  };

  const columns = [
    {
      header: "Points Required",
      accessor: (row: Offer) => row?.pointsRequired || "N/A",
    },
    {
      header: "Discount Value",
      accessor: (row: Offer) =>
        row?.discountType === "flat"
          ? `$ ${row?.discountValue?.toFixed(2)}`
          : `${row?.discountValue}%`,
    },
    {
      header: "Discount Type",
      accessor: (row: Offer) =>
        row?.discountType ? capitalize(row?.discountType) : "N/A",
    },
    {
      header: "Service Type",
      accessor: (row: Offer) =>
        row?.serviceType ? formatServiceTypes(row.serviceType) : "N/A",
    },
    {
      header: "Valid From",
      accessor: (row: Offer) =>
        row.validFrom ? formatDate(row.validFrom) : "N/A",
    },
    {
      header: "Valid To",
      accessor: (row: Offer) => (row.validTo ? formatDate(row.validTo) : "N/A"),
    },
    {
      header: "Actions",
      accessor: (row: Offer) => (
        <div className="flex items-center">
          <Toggle
            labelClassName="mr-2"
            label="Active"
            disabled={offerStatusChangeId === row._id}
            checked={row.isOfferActive === true}
            onChange={() =>
              handleOfferStatusChange(row._id, row.isOfferActive!)
            }
          />
          <Button
            onClick={() => {
              setSelectedOffer(row);
              setIsEditModalOpen(true);
            }}
            className="text-primary"
            variant="ghost"
          >
            Edit
          </Button>
          <Button
            variant="ghost"
            className="text-red-600"
            onClick={() => {
              setSelectedOffer(row);
              setIsDeleteModalOpen(true);
            }}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <FiltersContainer>
        <div className="py-4">
          <div className="flex justify-end mb-4">
            <Button
              variant="outline"
              onClick={() => setIsAddOfferModalVisible(true)}
            >
              Add offer
            </Button>
          </div>
          <div className="w-full flex flex-wrap items-center justify-end gap-4">
            <div className="flex items-center">
              <label className="mr-2" htmlFor="from_date">
                From:{" "}
              </label>
              <DatePicker
                onChange={(e) => setValidFrom(formatDate(e?.toString() || ""))}
                selected={validFrom ? new Date(validFrom) : null}
                placeholderText="MM/DD/YYYY"
              />
            </div>
            <div className="flex items-center">
              <label className="mr-2" htmlFor="from_date">
                To:{" "}
              </label>
              <DatePicker
                onChange={(e) => setValidTo(formatDate(e?.toString() || ""))}
                selected={validTo ? new Date(validTo) : null}
                placeholderText="MM/DD/YYYY"
              />
            </div>
            <Select
              containerClassName="flex-row"
              options={[{ label: "All", value: "all" }, ...serviceTypes]}
              placeholder="Service type"
              onChange={(e) => setServiceType(e.target.value)}
              value={serviceType}
            />

            <Select
              containerClassName="flex-row"
              options={[
                { label: "Yes", value: "1" },
                { label: "No", value: "0" },
              ]}
              placeholder="Active"
              onChange={(e) => setIsActive(e.target.value)}
              value={isActive}
            />
            <Button disabled={isApplyButtonDisabled()} onClick={handleSearch}>
              Apply
            </Button>
            <Button
              disabled={isClearButtonDisabled()}
              className="btn-outline border-primary text-primary"
              onClick={clearFilters}
            >
              Clear
            </Button>
          </div>
        </div>
      </FiltersContainer>
      <DataListView
        isFetching={isLoading}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        currentPage={currentPage}
        forcePage={currentPage}
        emptyStateMessage="No Data Found"
        columns={columns}
        data={offers}
      />
      <AddOfferModal
        onSuccess={handleGetAllOffers}
        isVisible={isAddOfferModalVisible}
        onHide={() => setIsAddOfferModalVisible(false)}
      />
      <EditOfferModal
        isVisible={isEditModalOpen}
        onHide={() => {
          setSelectedOffer(null);
          setIsEditModalOpen(false);
        }}
        onSuccess={handleGetAllOffers}
        offer={selectedOffer}
      />
      <ConfirmationDialog
        isVisible={isDeleteModalOpen}
        onHide={() => {
          setSelectedOffer(null);
          setIsDeleteModalOpen(false);
        }}
        isSubmitting={isDeletingOffer}
        isSubmitButtonDisabled={isDeletingOffer}
        handleClick={handleDeleteOffer}
        text="Are you sure you want to delete this offer?"
      />
    </>
  );
};

export default Offers;
