import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { Helmet } from "react-helmet-async";
import { BsFillBagDashFill } from "react-icons/bs";
import { GiMoneyStack, GiNotebook } from "react-icons/gi";
import { RiMotorbikeFill } from "react-icons/ri";
import ApiManager from "../utils/ApiManager";
import Select from "./Select";

type StatsResponseType = {
  brands: number | null;
  drivers: number | null;
  users: number | null;
  activeOrders: number | null;
  cancelledOrders: number | null;
  completedOrders: number | null;
  newDrivers: number | null;
  ordersCount: number | null;
  newUsers: number | null;
};

const Card = ({
  icon,
  label,
  value,
}: {
  icon: React.ReactNode;
  label: string;
  value: number | null;
}) => {
  return (
    value !== null && (
      <div className="px-6 shadow-lg flex w-full items-center rounded-lg h-32 bg-white">
        <div className="mr-4   flex justify-center">
          <div className="w-14 h-14 bg-blue-100 flex items-center justify-center rounded-full">
            {icon}
          </div>
        </div>
        <div>
          <p className="text-3xl font-semibold">
            <CountUp duration={0.5} end={value} />
          </p>
          <p className="text-gray-500 text-md">{label}</p>
        </div>
      </div>
    )
  );
};

const Dashboard = () => {
  const [stats, setStats] = useState<StatsResponseType>({
    brands: null,
    drivers: null,
    users: null,
    activeOrders: null,
    cancelledOrders: null,
    completedOrders: null,
    newDrivers: null,
    ordersCount: null,
    newUsers: null,
  });
  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filter, setFilter] = useState("all");

  const getStats = async () => {
    setLoading(true);
    let res = await new ApiManager().getDashboardStats();
    let res2 = await new ApiManager().getExtendedStats();
    if (res.payload && res2.payload) {
      setStats({
        brands: res.payload.data.brands,
        drivers: res.payload.data.drivers,
        users: res.payload.data.users,
        activeOrders: res2.payload.data.activeOrders,
        cancelledOrders: res2.payload.data.cancelledOrders,
        completedOrders: res2.payload.data.completedOrders,
        newDrivers: res2.payload.data.newDrivers,
        ordersCount: res2.payload.data.ordersCount,
        newUsers: res2.payload.data.newUsers,
      });
    }

    setLoading(false);
  };

  const filterStats = async () => {
    setFilterLoading(true);
    let res = await new ApiManager().filterExtendedStats(filter);
    if (res.payload) {
      setStats({
        ...stats,
        cancelledOrders: res.payload.data.cancelledOrders,
        completedOrders: res.payload.data.completedOrders,
        newDrivers: res.payload.data.newDrivers,
        ordersCount: res.payload.data.ordersCount,
        newUsers: res.payload.data.newUsers,
      });
    }
    setFilterLoading(false);
  };

  useEffect(() => {
    getStats();
  }, []);

  useEffect(() => {
    filterStats();
  }, [filter]);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div
        style={{ minHeight: "calc(100vh - 100px)" }}
        className="flex flex-col justify-center py-10"
      >
        <div>
          {loading === true ? (
            <div
              style={{ height: "100%" }}
              className="w-full flex items-center justify-center"
            >
              <div className="loader"></div>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 gap-4 w-full lg:grid-cols-3 place-items-center">
                <Card
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-blue-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clipRule="evenodd"
                      />
                    </svg>
                  }
                  value={stats?.users}
                  label="Total users registered"
                />

                <Card
                  value={stats?.drivers}
                  label="Total drivers registered"
                  icon={
                    <div className="w-14 h-14 bg-purple-100 flex items-center justify-center rounded-full">
                      <RiMotorbikeFill
                        className="text-purple-400"
                        fontSize="1.5rem"
                      />
                    </div>
                  }
                />

                <Card
                  icon={
                    <div className="w-14 h-14 bg-green-200 flex items-center justify-center rounded-full">
                      <BsFillBagDashFill
                        className="text-green-500"
                        fontSize="1.5rem"
                      />
                    </div>
                  }
                  label="Active orders"
                  value={stats?.activeOrders}
                />
              </div>
              <div
                className="bg-gray-200 rounded-lg mt-10 pb-10 px-4 lg:px-10 py-10"
                // style={{ height: "20rem" }}
              >
                <div className="w-full flex justify-end">
                  <div className="flex items-center">
                    <label htmlFor="filter">Filter by time:</label>
                    <Select
                      value={filter}
                      options={[
                        {
                          label: "All",
                          value: "all",
                        },
                        {
                          label: "Today",
                          value: "1",
                        },
                        {
                          label: "Last week",
                          value: "7",
                        },
                        {
                          label: "Last month",
                          value: "30",
                        },
                      ]}
                      onChange={(e) => setFilter(e.target.value)}
                      id="filter"
                      className="w-32 h-8 bg-white ml-2"
                    />
                    {/* <option value="all">All</option>
                      <option value="1">Today</option>
                      <option value="7">Last Week</option>
                      <option value="30">Last Month</option>
                    </Select> */}
                  </div>
                </div>
                {filterLoading === true ? (
                  <div className="w-full flex justify-center mb-20">
                    <div className="loader"></div>
                  </div>
                ) : (
                  <>
                    <div className="grid grid-cols-1 gap-10 place-items-center lg:grid-cols-3 mt-10 w-full justify-center">
                      <Card
                        icon={
                          <div className="w-14 h-14 bg-green-100 flex items-center justify-center rounded-full">
                            <GiNotebook
                              className="text-green-400"
                              fontSize="1.5rem"
                            />
                          </div>
                        }
                        label="All orders"
                        value={stats?.ordersCount}
                      />

                      <Card
                        icon={
                          <div className="w-14 h-14 bg-green-200 flex items-center justify-center rounded-full">
                            <GiMoneyStack
                              className="text-green-500"
                              fontSize="1.5rem"
                            />
                          </div>
                        }
                        label="Completed orders"
                        value={stats?.completedOrders}
                      />

                      <Card
                        icon={
                          <div className="w-14 h-14 bg-red-100 flex items-center justify-center rounded-full">
                            <BsFillBagDashFill
                              className="text-red-500"
                              fontSize="1.5rem"
                            />
                          </div>
                        }
                        label="Cancelled orders"
                        value={stats?.cancelledOrders}
                      />

                      <Card
                        icon={
                          <div className="w-14 h-14 bg-purple-100 flex items-center justify-center rounded-full">
                            <RiMotorbikeFill
                              className="text-purple-400"
                              fontSize="1.5rem"
                            />
                          </div>
                        }
                        value={stats?.newDrivers}
                        label="Drivers registered"
                      />
                      <Card
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 text-blue-500"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                              clipRule="evenodd"
                            />
                          </svg>
                        }
                        value={stats?.newUsers}
                        label="New users"
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
