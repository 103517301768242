import React from "react";
import { BiMenu } from "react-icons/bi";
import swyveryLogo from "../assets/swyvery-logo.png";
import { BASE_URL } from "../utils/config";
import { getEnvironmentFromUrl } from "../utils/helpers";
import { useAppDispatch } from "../Redux/store";
import { toggleSidebar } from "../Redux/slices/ui";

const TopBar = () => {
  const dispatch = useAppDispatch();

  const handleSidebarToggle = () => {
    dispatch(toggleSidebar());
  };

  return (
    <div className="w-full pr-10 bg-white justify-between flex items-center border-b border-gray-200 pl-10 h-20 sticky top-0 z-40">
      <div className="flex items-center">
        <img
          src={swyveryLogo}
          alt="Swyvery"
          className="w-10 h-10 object-contain"
        />
        <p className="font-inter text-lg lg:text-2xl font-normal text-[#0B4353]">
          SWYVERY
        </p>
        <BiMenu onClick={handleSidebarToggle} className="lg:hidden ml-4" />
      </div>
      <div className="bg-yellow-200 p-2 rounded">
        <p className="capitalize text-orange-600 text-sm font-semibold">
          {getEnvironmentFromUrl(BASE_URL!)}
        </p>
      </div>
    </div>
  );
};

export default TopBar;
