import React from "react";
import { Link, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

interface PropTypes {
  link: string;
  label: string;
  icon: React.ComponentType<{ className?: string }>;
  renderRight?: React.ReactElement | null;
}

const SidebarLink = ({ link, label, icon: Icon, renderRight }: PropTypes) => {
  const { pathname } = useLocation();

  // Special handling for dashboard path
  const isActive =
    link === "/dashboard"
      ? pathname === "/dashboard" // Exact match for dashboard
      : pathname.startsWith(link); // startsWith for other routes

  console.log(pathname, "pathname", link, "link");

  return (
    <Link to={link}>
      <div className="flex  group text-black items-center text-lg">
        {Icon && (
          <Icon
            className={twMerge(
              "shrink-0 mr-4 group-hover:text-primary transition transition-1000s text-gray-300",
              isActive && "text-primary"
            )}
          />
        )}
        <p className="shrink-0 text-lg">{label}</p>
        {renderRight && renderRight}
      </div>
    </Link>
  );
};

export default SidebarLink;
