import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import empty from "../assets/undraw_no_data_re_kwbl.svg";
import AddStateModal from "../components/AddStateModal";
import DataTable, { TBody, TH, THead, TR } from "../components/DataTable";
import Pagination from "../components/Pagination";
import Spinner from "../components/Spinner";
import ApiManager from "../utils/ApiManager";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { toast } from "react-toastify";
import Button from "../components/Button";
import FiltersContainer from "../components/FiltersContainer";
import Input from "../components/Input";

type StateType = {
  _id: string;
  name: string;
  symbol: string;
};

const States = () => {
  const [states, setStates] = useState<StateType[]>([]);
  const [isAddingState, setIsAddingState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [selectedStateId, setSelectedStateId] = useState("");
  const [isDeletingState, setIsDeletingState] = useState(false);

  const searchRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState("");
  const handleGetAllStates = async () => {
    setIsLoading(true);
    const res = await new ApiManager().getLaunchedStates({
      page: currentPage,
      search: searchValue,
    });
    if (res.success) {
      setStates(res.data?.docs);
      setTotalPages(res.data?.totalPages);
    }
    setIsLoading(false);
  };

  const handleStateDelete = async () => {
    setIsDeletingState(true);
    const res = await new ApiManager().deleteState(selectedStateId);
    if (res.success) {
      toast("State Deleted Successfully", { type: "success" });
      setSelectedStateId("");
      const updatedStates = states.filter(
        (state) => state._id !== selectedStateId
      );
      if (updatedStates.length === 0 && currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1);
      } else {
        handleGetAllStates();
      }
    } else {
      toast("Something went wrong, please try again later", { type: "error" });
    }
    setIsDeletingState(false);
  };

  useEffect(() => {
    handleGetAllStates();
  }, [currentPage, searchValue]);

  return (
    <>
      <Helmet>
        <title>States</title>
      </Helmet>
      <div className="w-full h-full">
        <div className="pb-4 flex flex-col justify-center font-inter bg-gray-100 ">
          <FiltersContainer>
            <div className="flex items-center my-4 justify-end">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setCurrentPage(1);
                  if (search) setSearchValue(search);
                }}
                className="flex"
              >
                <Input
                  ref={searchRef}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  className="w-52 ml-4 px-4 h-12 outline-none"
                  placeholder="search"
                />
                <Button
                  disabled={search === ""}
                  type="submit"
                  className="w-32 ml-4  bg-primary text-white"
                >
                  Search
                </Button>
                <Button
                  disabled={search === ""}
                  type="button"
                  onClick={() => {
                    setSearch("");
                    setSearchValue("");
                  }}
                  className="btn-outline border  border-primary w-20 ml-4 h-10 text-primary"
                >
                  Clear
                </Button>
              </form>
              <Button
                onClick={() => setIsAddingState(true)}
                className="btn-outline ml-4 border border-primary text-primary  w-32 py-2 "
              >
                Add state
              </Button>
            </div>
          </FiltersContainer>
          <div className="h-4/5 overflow-auto flex flex-col justify-between w-full bg-white rounded-lg">
            {isLoading ? (
              <Spinner />
            ) : states?.length === 0 ? (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <img className="w-1/5" src={empty} />
                <p className="mt-10 text-2xl text-gray-500 font-inter">
                  No States Found
                </p>
              </div>
            ) : (
              <DataTable>
                <THead>
                  <TR>
                    <TH>Name</TH>
                    <TH>Symbol</TH>
                    <TH>Actions</TH>
                  </TR>
                </THead>
                <TBody>
                  {states?.map((state, index: number) => (
                    <TR key={index}>
                      <TH>{state?.name || "N/A"}</TH>
                      <TH>{state?.symbol || "N/A"}</TH>
                      <TH>
                        <Button
                          onClick={() => setSelectedStateId(state._id)}
                          className="btn btn-ghost text-red-600"
                        >
                          Delete
                        </Button>
                      </TH>
                    </TR>
                  ))}
                </TBody>
              </DataTable>
            )}
          </div>
          <div className="mt-6">
            <Pagination
              currentPage={currentPage}
              page={totalPages}
              handlePageClick={(e: { selected: number }) =>
                setCurrentPage(e.selected + 1)
              }
            />
          </div>
        </div>
      </div>
      <AddStateModal
        isVisible={isAddingState}
        onHide={() => setIsAddingState(false)}
        onSuccess={handleGetAllStates}
      />
      <ConfirmationDialog
        handleClick={handleStateDelete}
        isSubmitButtonDisabled={isDeletingState}
        isSubmitting={isDeletingState}
        text="Are you sure you want to delete this state ?"
        isVisible={selectedStateId !== ""}
        onHide={() => setSelectedStateId("")}
      />
    </>
  );
};

export default States;
