import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import TopBar from "./TopBar";
import { useAppSelector } from "../Redux/store";
import { twMerge } from "tailwind-merge";

const DashboardLayout = () => {
  const isSidebarCollapsed = useAppSelector(
    (state) => state.ui.isSidebarCollapsed
  );

  return (
    <div className="w-full h-full">
      <TopBar />
      <div className="flex h-full w-full">
        <Sidebar />
        <div
          style={{ minHeight: "calc(100vh - 80px)" }}
          className={twMerge(
            "w-80% px-6 py-2 relative font-inter bg-gray-100 h-full",
            isSidebarCollapsed && "w-full"
          )}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
