// @ts-nocheck
import dayjs from "dayjs";
import React from "react";
import DatePickerComponent, { DatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiCalendar } from "react-icons/bi";

const range = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (_, i) => start + i);

type PropTypes = DatePickerProps & {
  label?: string;
  isInvalid?: boolean;
  error?: string;
};

const CustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: any) => {
  const years = range(1950, dayjs().year());
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div
      style={{
        margin: 10,
        display: "flex",
        justifyContent: "center",
        gap: "0.5rem",
      }}
    >
      <button
        className="bg-primary px-2 py-2 rounded text-white"
        onClick={() => decreaseMonth()}
        disabled={prevMonthButtonDisabled}
      >
        {"<"}
      </button>
      <select
        value={dayjs(date).year()}
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        value={months[dayjs(date).month()]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <button
        className="bg-primary px-2 py-2 rounded text-white"
        onClick={() => increaseMonth()}
        disabled={nextMonthButtonDisabled}
      >
        {">"}
      </button>
    </div>
  );
};

const CustomInput = React.forwardRef((props: any, ref: any) => {
  return (
    <div>
      <input
        {...props}
        autoComplete="off"
        className="w-full px-4 py-3 pr-10 bg-white border border-primary rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary sm:text-sm placeholder:text-gray-400"
      />
      <BiCalendar
        onClick={props.onClick}
        className="absolute right-3 top-1/2 cursor-pointer -translate-y-1/2 w-5 h-5 text-black"
      />
    </div>
  );
});

const DatePicker = ({
  isInvalid,
  error,
  label,
  selected,
  ...rest
}: PropTypes) => {
  const today = dayjs();

  return (
    <div className="w-full">
      {label ? (
        <div className="label flex-shrink-0 mb-1">
          <span className="label-text">{label}</span>
        </div>
      ) : null}
      <div className="join w-full">
        <DatePickerComponent
          {...rest}
          showYearDropdown
          selected={selected}
          wrapperClassName="w-full "
          customInput={<CustomInput />}
          dayClassName={(date) =>
            `${
              today.isSame(date, "day")
                ? "bg-white rounded-full border border-primary text-primary"
                : ""
            } `
          }
          calendarClassName="bg-white"
          // renderCustomHeader={CustomHeader}
        />
      </div>
      {isInvalid && error ? (
        <div className="label">
          <span className="label-text-alt text-red-600">{error}</span>
        </div>
      ) : null}
    </div>
  );
};

export default DatePicker;
