import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Button from "../components/Button";
import DatePicker from "../components/DatePicker";
import Spinner from "../components/Spinner";
import ApiManager from "../utils/ApiManager";
import { formatDate } from "../utils/helpers";

export type ActiveUsersApiResponse = {
  dimensions: string[];
  metrics: string[];
}[];

type AnalyticsType = {
  dimensions: string;
  metrics: number;
};

const AnalyticsCard = ({
  data,
  endDateValue,
  onEndDateChange,
  onStartDateChange,
  startDateValue,
  handleFilter,
  isLoading,
  heading,
}: {
  startDateValue: string;
  endDateValue: string;
  onStartDateChange: (e: Date | null | undefined) => void;
  onEndDateChange: (e: Date | null | undefined) => void;
  data: AnalyticsType[];
  handleFilter: () => void;
  isLoading: boolean;
  heading: string;
}) => {
  return (
    <div className="w-full  space-y-10 bg-white p-4 rounded-xl">
      <div className="flex lg:flex-row flex-col gap-4 items-center justify-between">
        <h1 className="text-2xl font-bold">{heading}</h1>
        <div className="flex items-center gap-4">
          <div className="flex  items-center gap-2">
            <label htmlFor="startDate">From: </label>
            <DatePicker
              selected={new Date(startDateValue)}
              onChange={onStartDateChange}
              placeholderText="From"
            />
          </div>
          <div className="flex items-center gap-2">
            <label htmlFor="endDate">To: </label>
            <DatePicker
              selected={new Date(endDateValue)}
              onChange={onEndDateChange}
              placeholderText="To"
            />
          </div>
          <Button onClick={handleFilter}>Filter</Button>
        </div>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <ResponsiveContainer debounce={300} width="100%" height={300}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              padding={{ right: 40 }}
              interval="preserveEnd"
              dataKey="dimensions"
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="metrics" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

const Analytics = () => {
  const today = new Date();
  const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

  const [activeUsers, setActiveUsers] = useState<AnalyticsType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState<string>(
    formatDate(sevenDaysAgo.toString())
  );
  const [endDate, setEndDate] = useState<string>(formatDate(today.toString()));
  const [firstOpen, setFirstOpen] = useState<AnalyticsType[]>([]);
  const [firstOpenStartDate, setFirstOpenStartDate] = useState(
    formatDate(sevenDaysAgo.toString())
  );
  const [firstOpenLastDate, setFirstOpenLastDate] = useState(
    formatDate(today.toString())
  );
  const [isFirstOpenLoading, setIsFirstOpenLoading] = useState(false);

  const handleGetActiveUsers = async () => {
    setIsLoading(true);
    const res = await new ApiManager().getActiveUsersData({
      startDate,
      endDate,
    });
    if (res?.success) {
      const arr = res.data?.map((item) => ({
        dimensions: `${item?.dimensions[0].slice(
          0,
          4
        )}-${item?.dimensions[0].slice(4, 6)}-${item?.dimensions[0].slice(6)}`,
        metrics: parseInt(item?.metrics[0]),
      }));
      const sortedArr = arr?.sort((a, b) =>
        a.dimensions.localeCompare(b.dimensions)
      );

      if (sortedArr) {
        setActiveUsers(sortedArr);
      }
    }
    setIsLoading(false);
  };

  const handleGetFirstOpen = async () => {
    setIsFirstOpenLoading(true);
    const res = await new ApiManager().getFirstOpenData({
      startDate: firstOpenStartDate,
      endDate: firstOpenLastDate,
    });
    if (res?.success) {
      setFirstOpen(res.data);
    }
    setIsFirstOpenLoading(false);
  };

  useEffect(() => {
    handleGetActiveUsers();
    handleGetFirstOpen();
  }, []);

  console.log({ startDate, endDate, firstOpenStartDate, firstOpenLastDate });

  return (
    <div className="space-y-10 py-4">
      <AnalyticsCard
        heading="Active users"
        data={activeUsers}
        endDateValue={endDate}
        handleFilter={handleGetActiveUsers}
        isLoading={isLoading}
        onEndDateChange={(e) => setEndDate(formatDate(e?.toString() || ""))}
        onStartDateChange={(e) => setStartDate(formatDate(e?.toString() || ""))}
        startDateValue={startDate}
      />
      <AnalyticsCard
        heading="First open"
        data={firstOpen}
        endDateValue={firstOpenLastDate}
        handleFilter={handleGetFirstOpen}
        isLoading={isFirstOpenLoading}
        onEndDateChange={(e) =>
          setFirstOpenLastDate(formatDate(e?.toString() || ""))
        }
        onStartDateChange={(e) =>
          setFirstOpenStartDate(formatDate(e?.toString() || ""))
        }
        startDateValue={firstOpenStartDate}
      />
    </div>
  );
};

export default Analytics;
