import { FormikProps } from "formik";
import React from "react";
import Button from "../Button";
import { ChangePasswordType } from "../../pages/Settings";
import Input from "../Input";

interface PropTypes {
  formik: FormikProps<ChangePasswordType>;
}

const ChangePasswordForm = ({ formik }: PropTypes) => {
  return (
    <form onSubmit={formik.handleSubmit}>
      <h1 className="text-3xl font-semibold mb-10">Settings</h1>
      <h1 className="text-xl font-semibold mb-6">Change password</h1>
      <div className="grid grid-cols-3 items-center gap-10 mb-4">
        <div className="flex flex-col">
          <label htmlFor="change_password">Old password:</label>
          <Input
            value={formik.values.oldPassword}
            name="oldPassword"
            onChange={formik.handleChange}
            className="w-30% h-14  mt-4 px-4 text-sm focus:outline-none"
            placeholder="Enter old password"
          />
        </div>
        <div className="flex flex-col ">
          <label htmlFor="change_password">New password:</label>
          <Input
            value={formik.values.newPassword}
            name="newPassword"
            onChange={formik.handleChange}
            className="w-30% h-14  mt-4 px-4 text-sm focus:outline-none"
            placeholder="New password"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="change_password">Confirm new password:</label>
          <Input
            value={formik.values.confirmNewPassword}
            name="confirmNewPassword"
            onChange={formik.handleChange}
            className="w-30% h-14  mt-4 px-4 text-sm focus:outline-none"
            placeholder="Confirm new password"
          />
        </div>
      </div>
      {Object.keys(formik.errors).length > 0 && (
        <div className="text-red-500 my-4">
          <p>{Object.values(formik.errors)[0]}</p>
        </div>
      )}
      <Button type="submit" isLoading={formik.isSubmitting}>
        Save
      </Button>
    </form>
  );
};

export default ChangePasswordForm;
