import dayjs from "dayjs";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";
import empty from "../assets/undraw_page_not_found_re_e9o6.svg";
import ApiManager from "../utils/ApiManager";
import { statusMap } from "../utils/Enums";
import {
  areBothDatesPresent,
  formatDate,
  isOneDatePresent,
} from "../utils/helpers";
import Button from "./Button";
import ConfirmationDialog from "./ConfirmationDialog";
import DatePicker from "./DatePicker";
import Pagination from "./Pagination";
import Select from "./Select";
import { ServiceType, serviceTypeMap } from "./ServicesDashboard";
import ViewFeedback from "./ViewFeedback";

type PropTypes = {
  userId?: string;
  driverId?: string;
};

type FormType = {
  fromDate: string;
  toDate: string;
  type: string;
  status: string;
  [key: string]: string;
};

const initialState = {
  fromDate: "",
  toDate: "",

  type: "",
  status: "",
};

const Table = ({ userId, driverId }: PropTypes) => {
  const [formState, setFormState] = useState<FormType>(initialState);
  const [page, setPage] = useState(1);
  const [selectedFeedback, setSelectedFeedback] = useState<{
    experienceRating: null | number | string;
    feedback: null | string;
    orderId: string;
  }>({ experienceRating: null, feedback: null, orderId: "" });
  const [status, setStatus] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCancelingService, setIsCancelingService] = useState(false);
  const [services, setServices] = useState<ServiceType[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [type, setType] = useState("");

  const handleGetServices = async () => {
    setLoading(true);
    const res = await new ApiManager().getAllServices({
      users: userId,
      drivers: driverId,
      page,
      fromdate: formState.fromDate,
      todate: formState.toDate,
      createdago: formState.past,
      type: formState.type,
      limit: 4,
      status: formState.status,
    });
    if (res?.success) {
      setServices(res?.payload?.data?.docs);
      setTotalPages(res?.payload?.data?.totalPages);
    }
    setLoading(false);
  };

  const form = useRef<HTMLFormElement>(null);

  const handlePageClick = async (event: { selected: number }) => {
    setPage(event.selected + 1);
  };

  const handleReset = () => {
    if (form.current) {
      form.current.reset();
    }
    setFormState({ fromDate: "", toDate: "", type: "", status: "" });
    setStatus("");
    setFromDate("");
    setToDate("");
    setType("");
    setPage(1);
  };

  const handleApply = (e: FormEvent) => {
    e.preventDefault();
    setPage(1);
    setFormState({
      fromDate: fromDate,
      toDate: toDate,
      status: status,
      type: type,
    });
  };

  const handleServiceCancel = async () => {
    setIsCancelingService(true);
    const res = await new ApiManager().cancelService(selectedServiceId);
    if (res.data) {
      setSelectedServiceId("");
      toast("Service Deleted Successfully", { type: "success" });
    } else {
      toast("Couldn't cancel service at this moment. Please try again later.", {
        type: "error",
      });
    }
    setIsCancelingService(false);
  };

  useEffect(() => {
    handleGetServices();
  }, [
    userId,
    driverId,
    formState.status,
    formState.fromDate,
    formState.toDate,
    formState.past,
    formState.type,
    page,
  ]);
  const isAnyFilterApplied = () => {
    if (status !== "" || type !== "" || fromDate !== "" || toDate !== "")
      return true;
    return false;
  };
  const isApplyButtonDisabled = () => {
    if (isAnyFilterApplied()) {
      return isOneDatePresent(fromDate, toDate);
    }
    return !areBothDatesPresent(fromDate, toDate);
  };

  const isClearButtonDisabled = () => {
    return status === "" && type === "" && fromDate === "" && toDate === "";
  };

  return (
    <>
      <div className="h-96 w-full mt-10 mb-4">
        <form
          onSubmit={handleApply}
          ref={form}
          className="w-full flex items-center justify-between"
        >
          <p className="text-xl">Orders</p>
          <div className="flex items-center">
            <div className="flex items-center mr-4">
              <div className="flex items-center">
                <label htmlFor="from_date" className="mr-2">
                  From:{" "}
                </label>
                <DatePicker
                  onChange={(e) => setFromDate(formatDate(e?.toString() || ""))}
                  selected={fromDate ? new Date(fromDate) : null}
                  name="fromDate"
                  id="from_date"
                  placeholderText="MM/DD/YYYY"
                  className="w-44 h-12 bg-white ml-2 px-2"
                />
              </div>
              <div className="flex items-center ml-4">
                <label htmlFor="to_date" className="mr-2">
                  To:{" "}
                </label>
                <DatePicker
                  placeholderText="MM/DD/YYYY"
                  onChange={(e) => setToDate(formatDate(e?.toString() || ""))}
                  selected={toDate ? new Date(toDate) : null}
                  name="toDate"
                  id="to_date"
                  className="w-44 h-12 bg-white ml-2 px-2"
                />
              </div>
            </div>

            <Select
              defaultValue=""
              onChange={(e) => setType(e.target.value)}
              value={type}
              name="type"
              placeholder="Type"
              options={[
                { label: "Package", value: "package_pickup_meta" },
                { label: "Curb", value: "curbside_pickup_meta" },
                { label: "In Store", value: "instore_pickup_meta" },
              ]}
              className="w-24 h-12  px-2 mr-4"
            />

            <Select
              defaultValue=""
              onChange={(e) => setStatus(e.target.value)}
              className="w-24 outline-none bg-white h-12 pl-4 pr-2 mr-4"
              placeholder="Status"
              options={[
                { label: "Unassigned", value: "unassigned" },
                { label: "Assigned", value: "assigned" },
                { label: "Way To Store", value: "wayToStore" },
                { label: "Arrived At Pickup", value: "arrivedAtPickup" },
                { label: "Confirm Items", value: "confirmItems" },
                { label: "Proceed To Dropoff", value: "proceedToDropoff" },
                { label: "Arrived At Dropoff", value: "arrivedAtDropoff" },
                { label: "Confirm Dropoff", value: "confirmDropoff" },
                { label: "Completed", value: "completed" },
                { label: "Cancelled", value: "cancelled" },
              ]}
            />

            {/* <select
              defaultValue=""
              onChange={handleChange}
              name="past"
              className="w-24 outline-none bg-white rounded-sm h-8 pl-4 pr-2 mr-4"
            >
              <option value="" disabled hidden>
                Time
              </option>
              <option value="">All</option>
              <option value="1">Today</option>
              <option value="7">Last Week</option>
              <option value="30">Last Month</option>
            </select> */}
            <Button disabled={isApplyButtonDisabled()} type="submit">
              Apply
            </Button>
            <Button
              disabled={isClearButtonDisabled()}
              className="ml-4"
              onClick={handleReset}
              type="button"
              variant="outline"
            >
              Clear
            </Button>
          </div>
        </form>
        <table className="w-full overflow-x-auto bg-white h-4/5  mt-4">
          <tr className="w-full justify-between h-20% border-b border-gray-200 flex items-center px-4 py-4">
            <td className="w-36 text-black">Date</td>
            <td className="w-36 text-black">Type</td>
            <td className="w-36 text-black">Driver</td>
            <td className="w-36 text-black">Status</td>

            <td className="w-56 text-black">Actions</td>
          </tr>
          {loading === true ? (
            <div className="flex pb-20 items-center justify-center">
              <div className="loader"></div>
            </div>
          ) : (
            <>
              {services?.length > 0 ? (
                services?.map((order) => (
                  <tr className="w-full h-20% border-b justify-between border-gray-200 flex items-center text-left px-4 py-4">
                    <td className="w-36 truncate	 text-gray-500">
                      {dayjs(order?.createdAt).format("DD-MM-YYYY")}
                    </td>
                    <td className="w-36 text-left text-gray-500">
                      {serviceTypeMap[order?.serviceMetaType]}
                    </td>
                    <td className="w-36 truncate	 text-gray-500">
                      {order?.driver?.length === 0
                        ? "N/A"
                        : order.driver[0].fullName}
                    </td>
                    <td
                      className={twMerge(
                        "w-36 truncate text-orange-600",
                        order?.status === "cancelled" && "text-red-600",
                        order?.status === "ratedByUser" && "text-green-600"
                      )}
                    >
                      {statusMap(order?.status)}
                    </td>

                    <td className="w-56 flex items-center gap-6">
                      <Link to={`/dashboard/services/${order?._id}`}>
                        <button className="h-8 rounded text-primary">
                          Details
                        </button>
                      </Link>
                      {driverId && order?.ratingByUser && (
                        <button
                          onClick={() =>
                            setSelectedFeedback({
                              experienceRating: order?.ratingByUser?.rating,
                              feedback: order?.ratingByUser?.feedback,
                              orderId: order?._id,
                            })
                          }
                          className="h-8 rounded text-primary"
                        >
                          View Feedback
                        </button>
                      )}
                      {order?.status ===
                      "ratedByUser" ? null : order?.status ===
                        "cancelled" ? null : (
                        <button
                          onClick={() => setSelectedServiceId(order?._id)}
                          className="text-red-600 ml-4"
                        >
                          Cancel
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <div className="w-full flex-col h-80% flex items-center justify-center">
                  <img alt="Empty" className="w-20 h-20" src={empty} />
                  <p>No data found</p>
                </div>
              )}
            </>
          )}
        </table>
        <div className="h-1/5 w-full">
          <Pagination
            currentPage={page}
            page={totalPages}
            handlePageClick={handlePageClick}
          />
        </div>
      </div>
      <ViewFeedback
        feedback={selectedFeedback}
        screen="driverDetails"
        isVisible={selectedFeedback.orderId !== ""}
        setVisible={() =>
          setSelectedFeedback({
            experienceRating: null,
            feedback: "",
            orderId: "",
          })
        }
      />
      <ConfirmationDialog
        isSubmitButtonDisabled={isCancelingService}
        handleClick={handleServiceCancel}
        isVisible={selectedServiceId !== ""}
        onHide={() => setSelectedServiceId("")}
        text="Are you sure you want to cancel this service ?"
      />
    </>
  );
};

export default Table;
