import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ApiManager from "../utils/ApiManager";
import {
  isFileFormatValid,
  isFileSizeValid,
  isValidPhoneNumber,
} from "../utils/helpers";
import Button from "./Button";
import FileInput from "./FileInput";
import Input from "./Input";
import Modal from "./Modal";
import PasswordInput from "./PasswordInput";
import PhoneInput from "./PhoneInput";
import Select from "./Select";
import Spinner from "./Spinner";

interface PropTypes {
  isVisible: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const CreateUser = ({ isVisible, onHide, onSuccess }: PropTypes) => {
  const [isLoading, setIsLoading] = useState(false);
  const [states, setStates] = useState([]);

  const form = useRef<HTMLFormElement>(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      gender: "",
      repeat_password: "",
      phone: "",
      profileImage: "",
      state: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .trim()
        .matches(/^[A-Za-z\s]+$/, "Name must contain only letters")
        .required("This field is required"),
      email: Yup.string().trim().email().required("This field is required"),
      password: Yup.string()
        .trim()
        .min(6, "Password must be at least 6 characters")
        .required("This field is required"),
      repeat_password: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("This field is required"),
      gender: Yup.string().required("This field is required"),
      phone: Yup.string()
        .test("phone", "Invalid phone number", function (value) {
          if (!value) return false;
          return isValidPhoneNumber(value);
        })
        .required("This field is required"),
      state: Yup.string().required("This field is required"),
      profileImage: Yup.mixed()
        .required("This field is required")
        .test("format", "File must be an Image or PDF", (value) => {
          if (value && value instanceof File) {
            return isFileFormatValid(value);
          }
        })
        .test("size", "File size must be 5MB or less", (value) => {
          if (value && value instanceof File) {
            return isFileSizeValid(value);
          }
        }),
    }),
    onSubmit: async (values) => {
      let res = await new ApiManager().createUser(
        values.name,
        values.email?.toLowerCase(),
        values.gender,
        values.password,
        values.repeat_password,
        values.phone,
        values.profileImage,
        values.state
      );
      if (res?.success) {
        toast("User Created Successfully", {
          type: "success",
        });
        formik.resetForm();
        onSuccess();
        onHide();
      } else {
        toast(res?.err?.error, {
          type: "error",
        });
      }
    },
  });

  const handleImageChange = (file: File) => {
    formik.setFieldValue("profileImage", file);
  };

  const handleGetAllStates = async () => {
    setIsLoading(true);
    const res = await new ApiManager().getAllStates();
    if (res.success) {
      setStates(res.data?.states);
    }
    setIsLoading(false);
  };

  const resetForm = () => {
    formik.resetForm();
  };

  useEffect(() => {
    handleGetAllStates();
  }, []);

  return (
    <Modal
      heading="Add New User"
      isVisible={isVisible}
      onHide={() => {
        resetForm();
        onHide();
      }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <form
            ref={form}
            onSubmit={formik.handleSubmit}
            className="w-full gap-4 grid grid-cols-2 py-10 bg-white rounded px-8 justify-center"
          >
            <Input
              className="input-primary"
              isInvalid={formik.touched.name && Boolean(formik.errors.name)}
              label="Full Name: *"
              name="name"
              onBlur={formik.handleBlur}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.errors.name}
              placeholder="Enter User's Name"
            />
            <Input
              className="input-primary"
              isInvalid={Boolean(formik.touched.email && formik.errors.email)}
              label="Email: *"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              name="email"
              onChange={formik.handleChange}
              error={formik.errors.email}
              type="email"
              autoComplete="off"
              placeholder="Enter User's Email"
            />
            <PasswordInput
              className="input-primary"
              label="Password: *"
              onBlur={formik.handleBlur}
              autoComplete="new-password"
              name="password"
              onChange={formik.handleChange}
              error={formik.errors.password}
              value={formik.values.password}
              type="password"
              isInvalid={Boolean(
                formik.touched.password && formik.errors.password
              )}
              placeholder="Enter User's Password"
            />
            <PasswordInput
              className="input-primary"
              label="Confirm Password: *"
              value={formik.values.repeat_password}
              onBlur={formik.handleBlur}
              autoComplete="new-password"
              name="repeat_password"
              onChange={formik.handleChange}
              error={formik.errors.repeat_password}
              type="password"
              isInvalid={Boolean(
                formik.touched.repeat_password && formik.errors.repeat_password
              )}
              placeholder="Confirm User's Password"
            />
            <PhoneInput
              error={formik.errors.phone}
              value={formik.values.phone}
              label="Phone: *"
              isInvalid={Boolean(formik.touched.phone && formik.errors.phone)}
              placeholder="Enter user's phone"
              name="phone"
              onChange={(e) => formik.setFieldValue("phone", e)}
              onBlur={formik.handleBlur}
            />
            <FileInput
              label="Profile Image *"
              error={formik.errors.profileImage}
              onChange={(e) => {
                if (e.target.files) {
                  handleImageChange(e.target.files[0]);
                }
              }}
              isInvalid={Boolean(
                formik.touched.profileImage && formik.errors.profileImage
              )}
            />
            <Select
              name="state"
              label="State: *"
              value={formik.values.state}
              placeholder="Select User's State"
              error={formik.errors.state}
              onChange={formik.handleChange}
              options={states}
              isInvalid={Boolean(formik.touched.state && formik.errors.state)}
            />
            <Select
              name="gender"
              label="Gender: *"
              isInvalid={Boolean(formik.touched.gender && formik.errors.gender)}
              onBlur={formik.handleBlur}
              value={formik.values.gender}
              placeholder="Select User's Gender"
              error={formik.errors.gender}
              onChange={formik.handleChange}
              options={[
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
                { label: "Other", value: "other" },
              ]}
            />
            <div className="col-span-2 text-center">
              <Button
                type="submit"
                isLoading={formik.isSubmitting}
                className="bg-primary text-white w-32"
              >
                Add
              </Button>
            </div>
          </form>
        </>
      )}
    </Modal>
  );
};

export default CreateUser;
