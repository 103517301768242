import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import empty from "../assets/undraw_no_data_re_kwbl.svg";
import ConfirmationDialog from "../components/ConfirmationDialog";
import DataTable, { TBody, TH, THead, TR } from "../components/DataTable";
import Pagination from "../components/Pagination";
import SendNotificationModal from "../components/SendNotificationModal";
import Spinner from "../components/Spinner";
import ViewNotification from "../components/ViewNotification";
import ApiManager from "../utils/ApiManager";
import { capitalize } from "../utils/helpers";
import FiltersContainer from "../components/FiltersContainer";

export type NotificationType = {
  title: string;
  body: string;
  status: string;
  _id: string;
  userType: string;
  __v: number;
  targets: string[];
  createdAt: string;
  updatedAt: string;
};

const Notification = () => {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeletingNotification, setIsDeletingNotification] = useState(false);
  const [isSendNotificationModalOpen, setIsSendNotificationModalOpen] =
    useState(false);
  const [app, setApp] = useState("user");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedNotificationId, setSelectedNotificationId] = useState("");
  const [selectedNotification, setSelectedNotification] =
    useState<NotificationType | null>(null);

  const handleGetAllNotifications = async () => {
    setIsLoading(true);
    const res = await new ApiManager().getAllNotifications({
      userType: app,
      limit: 10,
      page: currentPage,
    });
    if (res.success) {
      setNotifications(res.data?.docs);
      setTotalPages(res.data?.totalPages);
    }
    setIsLoading(false);
  };
  // const { data: notifications, isFetching: isLoading } =
  //   useGetAllNotificationsQuery({
  //     userType: app,
  //     limit: 10,
  //     page: currentPage,
  //   });

  // const [deleteNotification, { isLoading: isDeletingNotification }] =
  //   useDeleteNotificationMutation();

  const handlePageClick = (e: { selected: number }) => {
    setCurrentPage(e.selected + 1);
  };

  const handleDeleteNotification = async () => {
    setIsDeletingNotification(true);
    const res = await new ApiManager().deleteNotification(
      selectedNotificationId
    );
    if (res.success) {
      const updatedNotifications = notifications?.filter(
        (noti: NotificationType) => noti?._id !== selectedNotificationId
      );
      handleGetAllNotifications();
      if (updatedNotifications?.length === 0 && currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1);
      }
      setSelectedNotificationId("");
    }
    setIsDeletingNotification(false);
  };

  useEffect(() => {
    handleGetAllNotifications();
  }, [currentPage, app]);

  return (
    <>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <div className="w-full h-full">
        <div className="pb-4 flex flex-col justify-center font-inter bg-gray-100 ">
          <FiltersContainer>
            <div className="flex items-center justify-end my-4">
              <div className="flex items-center">
                <div className="px-3 py-3 bg-white rounded-full">
                  <button
                    onClick={() => {
                      setApp("user");
                      setCurrentPage(1);
                    }}
                    className={`${
                      app === "user"
                        ? "bg-primary text-white"
                        : "bg-transparent text-primary"
                    } rounded-full w-32 py-2 relative`}
                  >
                    Users
                  </button>
                  <button
                    onClick={() => {
                      setApp("driver");
                      setCurrentPage(1);
                    }}
                    className={`${
                      app === "driver"
                        ? "bg-primary text-white"
                        : "bg-transparent text-primary"
                    } rounded-full w-32 py-2 relative`}
                  >
                    Drivers
                  </button>
                </div>
                {/* <Link to="/dashboard/send-notification"> */}
                <button
                  onClick={() => setIsSendNotificationModalOpen(true)}
                  className="border border-primary text-primary px-4 py-3 rounded ml-4"
                >
                  Send notification
                </button>
                {/* </Link> */}
              </div>
            </div>
          </FiltersContainer>
          <div className="h-4/5 overflow-auto flex flex-col justify-between w-full bg-white rounded-lg">
            {isLoading ? (
              <Spinner />
            ) : !isLoading && notifications?.length === 0 ? (
              <div className="w-full  h-full flex flex-col items-center justify-center">
                <img className="w-1/5" src={empty} />
                <p className="mt-10 text-2xl text-gray-500 font-inter">
                  No Notifications Found
                </p>
              </div>
            ) : (
              <DataTable>
                <THead>
                  <TR>
                    <TH>Title</TH>
                    <TH>Body</TH>
                    <TH>User Type</TH>
                    <TH>Actions</TH>
                  </TR>
                </THead>
                <TBody>
                  {notifications?.map((noti) => (
                    <TR key={noti?._id}>
                      <TH>{noti?.title || "N/A"}</TH>
                      <TH>
                        <div className="relative group flex items-center">
                          {noti?.body ? noti?.body.slice(0, 40) : "N/A"}{" "}
                          {noti?.body.length > 40 ? (
                            <p
                              onClick={() => setSelectedNotification(noti)}
                              className="text-xs cursor-pointer"
                            >
                              ...Read More
                            </p>
                          ) : null}{" "}
                        </div>
                      </TH>
                      <TH>
                        {noti?.userType ? capitalize(noti?.userType) : "N/A"}
                      </TH>
                      <TH>
                        <button
                          onClick={() => setSelectedNotificationId(noti?._id)}
                          className={`text-red-500  ${
                            isDeletingNotification
                              ? "opacity-75 cursor-not-allowed"
                              : "opacity-100 cursor-pointer"
                          }`}
                          disabled={isDeletingNotification}
                        >
                          Delete
                        </button>
                      </TH>
                    </TR>
                  ))}
                </TBody>
              </DataTable>
            )}
          </div>
          <div className="mt-6">
            <Pagination
              currentPage={currentPage}
              page={totalPages}
              handlePageClick={(e: { selected: number }) => handlePageClick(e)}
            />
          </div>
        </div>
      </div>
      <ConfirmationDialog
        text="Are you sure you want to delete this notification?"
        isVisible={selectedNotificationId !== ""}
        onHide={() => setSelectedNotificationId("")}
        handleClick={handleDeleteNotification}
        isSubmitButtonDisabled={isDeletingNotification}
      />
      <ViewNotification
        isVisible={selectedNotification !== null}
        notification={selectedNotification}
        onHide={() => setSelectedNotification(null)}
      />
      <SendNotificationModal
        isVisible={isSendNotificationModalOpen}
        onHide={() => setIsSendNotificationModalOpen(false)}
      />
    </>
  );
};

export default Notification;
