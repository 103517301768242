import { useFormik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { IoMdSend } from "react-icons/io";
import InfiniteScroll from "react-infinite-scroll-component";
import { twMerge } from "tailwind-merge";
import * as Yup from "yup";
import socket from "../../lib/socket-io";
import ApiManager from "../../utils/ApiManager";
import { formatDate } from "../../utils/helpers";
import Button from "../Button";
import Input from "../Input";
import Modal from "../Modal";
import Spinner from "../Spinner";
import { ComplaintType, reportingMap } from "./ViewDetails";

interface ChatModalProps {
  isVisible: boolean;
  onHide: () => void;
  complaint: ComplaintType | null;
}

type MessageType = {
  complaint: string;
  from: string;
  message: string;
  status: string;
  userType: string;
  _id: string;
  createdAt: string;
};

const MessageBubble = ({
  message,
  date,
  from,
  userName,
}: {
  message: string;
  from: string;
  date: string;
  userName?: string;
}) => {
  return (
    <div className={twMerge("w-1/2 space-y-2", from === "admin" && "self-end")}>
      {userName && (
        <p className="text-black text-end text-sm self-end">{userName}</p>
      )}
      <p
        className={twMerge(
          "bg-gray-200  rounded-tl-2xl rounded-br-2xl rounded-bl-2xl px-4 py-2",
          from === "admin" && "bg-primary text-white"
        )}
      >
        {message}
      </p>
      {date && <p className="text-gray-500 text-sm">{formatDate(date)}</p>}
    </div>
  );
};

const ChatModal = ({ isVisible, onHide, complaint }: ChatModalProps) => {
  const [replies, setReplies] = useState<MessageType[]>([]);
  const [isRepliesLoading, setIsRepliesLoading] = useState(false);
  const [repliesCurrentPage, setRepliesCurrentPage] = useState(0);
  const [repliesTotalDocs, setRepliesTotalDocs] = useState(1);
  const currentComplaintId = useRef<string | null>(null);

  const messageForm = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: Yup.object().shape({
      message: Yup.string().trim().required("This field is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!complaint) return;
      const res = await new ApiManager().sendComplaintReply({
        complaint: complaint?._id,
        to: complaint[reportingMap[complaint?.userType]]?._id,
        toUserType: complaint?.userType,
        message: values.message,
      });
      if (res?.success) {
        setReplies((prevReplies) => [res?.data?.data, ...prevReplies]);
        resetForm();
      }
    },
  });

  const getComplaintReplies = async () => {
    if (repliesCurrentPage < 1) {
      setIsRepliesLoading(true);
    }
    const res = await new ApiManager().getComplaintReplies(complaint?._id!, {
      sort: "-1",
      page: repliesCurrentPage + 1,
    });
    if (res.success) {
      setReplies([...replies, ...res.data?.docs]);
      setRepliesTotalDocs(res.data?.totalDocs);
      setRepliesCurrentPage(res.data?.page);
    }
    setIsRepliesLoading(false);
  };

  // Set up socket listener when component mounts
  useEffect(() => {
    const handleNewMessage = (data: { type: string; data: MessageType }) => {
      if (data.data?.complaint === currentComplaintId.current) {
        setReplies((prevReplies) => [data.data, ...prevReplies]);
      }
    };

    socket.on("receive_helpdesk_message_success", handleNewMessage);

    return () => {
      socket.off("receive_helpdesk_message_success", handleNewMessage);
    };
  }, []); // Empty dependency array since we're using ref

  // Update complaint ID ref when complaint changes
  useEffect(() => {
    currentComplaintId.current = complaint?._id || null;
  }, [complaint?._id]);

  // Fetch initial replies when complaint changes
  useEffect(() => {
    if (complaint?._id) {
      setReplies([]);
      setRepliesCurrentPage(0);
      setRepliesTotalDocs(1);
      getComplaintReplies();
    }
  }, [complaint?._id]);

  return (
    <Modal
      isVisible={isVisible}
      onHide={() => {
        setReplies([]);
        setRepliesCurrentPage(0);
        messageForm.resetForm();
        setRepliesTotalDocs(0);
        onHide();
      }}
    >
      {complaint ? (
        <div className="space-y-4">
          <p className="pl-4 capitalize font-semibold text-lg">
            {complaint[reportingMap[complaint.userType]]?.fullName || "N/A"}
          </p>
          {isRepliesLoading ? (
            <Spinner />
          ) : (
            <>
              {replies.length > 0 ? (
                <div
                  id="scrollableDiv"
                  style={{
                    height: 300,
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column-reverse",
                  }}
                >
                  <InfiniteScroll
                    loader="Loading..."
                    next={getComplaintReplies}
                    dataLength={repliesCurrentPage * 10}
                    hasMore={repliesTotalDocs / 10 > repliesCurrentPage}
                    inverse={true}
                    height={300}
                    className="flex px-4 overflow-auto gap-y-2 flex-col-reverse"
                  >
                    {replies?.map((reply, index) => (
                      <MessageBubble
                        date={reply?.createdAt}
                        from={reply?.userType}
                        message={reply?.message}
                        key={reply?._id}
                      />
                    ))}
                  </InfiniteScroll>
                </div>
              ) : (
                <p className="text-center py-4 ">No Messages</p>
              )}

              <form
                onSubmit={messageForm.handleSubmit}
                className="flex items-center w-full gap-2"
              >
                <Input
                  placeholder="Type a message"
                  containerClassName="w-full"
                  name="message"
                  onChange={messageForm.handleChange}
                  disabled={messageForm.isSubmitting}
                  value={messageForm.values.message}
                />
                <Button
                  isLoading={messageForm.isSubmitting}
                  type="submit"
                  className="flex-shrink-0 w-14 h-14 rounded-full bg-primary flex items-center justify-center"
                >
                  <IoMdSend className="text-white" />
                </Button>
              </form>
            </>
          )}
        </div>
      ) : null}
    </Modal>
  );
};

export default ChatModal;
