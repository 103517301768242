import { createSlice } from "@reduxjs/toolkit";

const initialState = { driverUnReviewedCount: 0, isSidebarCollapsed: false };

const uiSlice = createSlice({
  name: "uiSlice",
  initialState,
  reducers: {
    setCount: (state, action) => {
      state.driverUnReviewedCount = action.payload;
    },
    decrementCount: (state) => {
      state.driverUnReviewedCount -= 1;
    },
    toggleSidebar: (state) => {
      state.isSidebarCollapsed = !state.isSidebarCollapsed;
    },
  },
});

export const { setCount, decrementCount, toggleSidebar } = uiSlice.actions;

export default uiSlice.reducer;
