import React from "react";
import { twMerge } from "tailwind-merge";
import amazing from "../assets/amazing-outline.png";
import average from "../assets/average-outline.png";
import bad from "../assets/bad-outline.png";
import amazingHighlighted from "../assets/feedback-amazing.png";
import averageHighlighted from "../assets/feedback-average.png";
import badHighlighted from "../assets/feedback-bad.png";
import goodHighlighted from "../assets/feedback-good.png";
import terribleHighlighted from "../assets/feedback-terrible.png";
import good from "../assets/good-outline.png";
import terrible from "../assets/terrible-outline.png";
import { experienceRatingMap } from "./Feedback";

interface PropTypes {
  feedback: {
    experienceRating: number;
  };
  showLabel?: boolean;
  emojiClassName?: string;
}

const emojis = [
  {
    experienceRating: 1,
    inactive: terrible,
    active: terribleHighlighted,
  },
  { experienceRating: 2, inactive: bad, active: badHighlighted },
  {
    experienceRating: 3,
    inactive: average,
    active: averageHighlighted,
  },
  { experienceRating: 4, inactive: good, active: goodHighlighted },
  {
    experienceRating: 5,
    inactive: amazing,
    active: amazingHighlighted,
  },
];
const ExperienceRating = ({
  feedback,
  showLabel = false,
  emojiClassName,
}: PropTypes) => {
  return emojis.map((emoji, index) => {
    const active = feedback?.experienceRating === emoji.experienceRating;
    return (
      <div className="flex flex-col flex-shrink-0 items-center justify-start gap-2">
        <img
          key={emoji.experienceRating + index}
          className={twMerge(
            "w-12 h-12 flex-shrink-0 object-contain",
            emojiClassName
          )}
          src={active ? emoji.active : emoji.inactive}
        />
        {showLabel && active && (
          <p>{experienceRatingMap[feedback.experienceRating]}</p>
        )}
      </div>
    );
  });
};

export default ExperienceRating;
