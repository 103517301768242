import React, { useEffect, useMemo, useState } from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";
import { ApiResponse } from "../../pages/HelpDesk";
import ApiManager from "../../utils/ApiManager";
import Button from "../Button";
import Map from "../Map";
import Modal from "../Modal";
import Spinner from "../Spinner";
import ChatModal from "./ChatModal";

interface PropTypes {
  isVisible: boolean;
  onHide: () => void;
  id: string;
}

export type ComplaintType = ApiResponse & {
  [key: string]: any;
  instore_pickup_meta: any;
  service: {
    [key: string]: any;
    serviceMetaType: string;
  };
};

export const reportingMap: { [key: string]: string } = {
  user: "reportingUser",
  driver: "reportingDriver",
};

const ViewDetails = ({ isVisible, onHide, id }: PropTypes) => {
  const [data, setData] = useState<ComplaintType | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isChatWindowVisible, setChatWindowVisible] = useState(false);

  const handleGetComplaintById = async () => {
    setIsLoading(true);
    const res = await new ApiManager().getComplaintById(id);
    if (res.success) {
      setData(res.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (id !== "") {
      handleGetComplaintById();
    }
  }, [id]);

  const isMapsDataAvailable = useMemo(() => {
    if (!data) return false;
    return data?.service?.serviceMetaType === "instore_pickup_meta"
      ? data?.instore_pickup_meta[0]?.deliveryLocation &&
          data?.instore_pickup_meta[0]?.pickupLocation &&
          data?.instore_pickup_meta[0]?.deliveryLocation?.lat &&
          data?.instore_pickup_meta[0]?.deliveryLocation?.lng &&
          data?.instore_pickup_meta[0]?.pickupLocation?.lat &&
          data?.instore_pickup_meta[0]?.pickupLocation?.lng
      : data[data?.service?.serviceMetaType]?.deliveryLocation &&
          data[data?.service?.serviceMetaType]?.pickupLocation &&
          data[data?.service?.serviceMetaType]?.deliveryLocation?.lat &&
          data[data?.service?.serviceMetaType]?.deliveryLocation?.lng &&
          data[data?.service?.serviceMetaType]?.pickupLocation?.lat &&
          data[data?.service?.serviceMetaType]?.pickupLocation?.lng;
  }, [data]);

  return (
    <Modal isVisible={isVisible} onHide={onHide}>
      <div className="w-full flex flex-col justify-center gap-4 h-full">
        {isLoading ? (
          <Spinner loaderClassName="mt-0" />
        ) : (
          data && (
            <>
              <p>
                <strong>Reported by: </strong>
                {data?.service[data?.userType] ? (
                  <Link
                    to={
                      data?.userType === "user"
                        ? `/dashboard/users/${data?.service?.user?._id}`
                        : `/dashboard/drivers/${data?.service?.driver?._id}`
                    }
                  >
                    <span className="text-primary underline">
                      {data[reportingMap[data?.userType]]?.fullName || "N/A"}
                    </span>
                  </Link>
                ) : (
                  <span className="underline text-primary">N/A</span>
                )}
              </p>
              <p>
                <strong>Title: </strong>
                {data?.title || "N/A"}
              </p>
              <div className="flex items-center justify-between">
                <p>
                  <strong>Description: </strong>
                </p>
                {data?.service?.serviceId && (
                  <Link to={`/dashboard/services/${data?.service?.serviceId}`}>
                    <IoIosInformationCircleOutline
                      size={24}
                      className="text-primary"
                    />
                  </Link>
                )}
              </div>
              <div className="border border-primary rounded-xl p-4">
                <p>{data?.description || "N/A"}</p>
              </div>
              <div className="flex items-center justify-between">
                <p>
                  <strong>User: </strong>
                  {data?.service?.user ? (
                    <Link to={`/dashboard/users/${data?.service?.user?._id}`}>
                      <span className="text-primary underline">
                        {data?.service?.user?.fullName}
                      </span>
                    </Link>
                  ) : (
                    <span className="text-primary underline">N/A</span>
                  )}
                </p>
                <p>
                  <strong>Driver: </strong>
                  {data?.service?.driver ? (
                    <Link
                      to={`/dashboard/drivers/${data?.service?.driver?._id}`}
                    >
                      <span className="text-primary underline">
                        {data?.service?.driver?.fullName}
                      </span>
                    </Link>
                  ) : (
                    <span className="text-primary underline">N/A</span>
                  )}
                </p>
              </div>
              {isMapsDataAvailable && (
                <div className="h-56 w-full rounded-xl">
                  <Map
                    mapContainerClassName="rounded-xl"
                    lngg={
                      data?.service?.serviceMetaType === "instore_pickup_meta"
                        ? data?.instore_pickup_meta[0]?.deliveryLocation?.lng
                        : data[data?.service?.serviceMetaType]?.deliveryLocation
                            ?.lng
                    }
                    latt={
                      data?.service?.serviceMetaType === "instore_pickup_meta"
                        ? data?.instore_pickup_meta[0]?.deliveryLocation?.lat
                        : data[data?.service?.serviceMetaType]?.deliveryLocation
                            ?.lat
                    }
                    pickupLatt={
                      data?.service?.serviceMetaType === "instore_pickup_meta"
                        ? data?.instore_pickup_meta[0]?.pickupLocation?.lat
                        : data[data?.service?.serviceMetaType]?.pickupLocation
                            ?.lat
                    }
                    pickupLngg={
                      data?.service?.serviceMetaType === "instore_pickup_meta"
                        ? data?.instore_pickup_meta[0]?.pickupLocation?.lng
                        : data[data?.service?.serviceMetaType]?.pickupLocation
                            ?.lng
                    }
                  />
                </div>
              )}
              <div className="flex items-center justify-center">
                <Button
                  onClick={() => setChatWindowVisible(true)}
                  className="w-32"
                >
                  Chat
                </Button>
              </div>
            </>
          )
        )}
      </div>
      <ChatModal
        complaint={data}
        isVisible={isChatWindowVisible}
        onHide={() => setChatWindowVisible(false)}
      />
    </Modal>
  );
};

export default ViewDetails;
